import { Injectable } from '@angular/core';
import { Endpoint, ICreateLog, ILog } from '@shared';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ExportedLog } from '@backend/models/Log';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private api: ApiService) {}

  public createLog(log: ICreateLog): void {
    this.api.post<ILog, ICreateLog>(Endpoint.LOG, log).subscribe();
  }

  public exportLogs({ startDate, endDate }: { startDate?: NgbDateStruct; endDate?: NgbDateStruct } = {}): Observable<ExportedLog[]> {
    return this.api.post<ExportedLog[], { startDate?: NgbDateStruct; endDate?: NgbDateStruct }>(Endpoint.EXPORT_LOG, {
      startDate,
      endDate
    });
  }
}
