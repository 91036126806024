import { ApplicationModules, MMOModelStatus, IAggregationStructure, Roles, IUser } from '@shared';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouteDataService } from '@front/services/route-data.service';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@front/services/auth.service';
import { ROUTER_LOGIN_PATH } from '@front/utils/variables';
import { AppInitService } from '@front/services/app-init.service';
import { SubSink } from 'subsink';
import { BreadcrumbService, IBreadcrumbPath } from '@front/services/breadcrumb.service';
import { fadeInAnimation } from '@front/app/modules/shared/animations/fade-in.animation';
import { AppStateService } from '@front/services/state/app-state.service';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { ENUMS } from './configs/configs-injector/configs-injector.module';
import { fadeOutAnimation } from './modules/shared/animations/fade-out.animation';
import { NotificationService } from './modules/shared/notification/notification.service';
import { AggregationStructureService } from '@front/services/api/aggregation-structure.service';
import { isDebugToolsActive } from '@front/utils/debug';
import { AggregationStructureModalComponent } from '~/app/modules/shared/aggregation-structure-modal/aggregation-structure-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from '@front/services/permission.service';
import { ConfigService } from '@front/services/api/config.api.service';
import { PowerBiService } from './modules/reports/services/power-bi.service';
import { ApplicationPermissionsService } from '@front/services/application-permissions.service';
import { UserService } from '@front/services/api/user.service';
import { LogService } from '@front/services/log.service';

declare let gtag: Function;

interface INavBarContent {
  value: string;
  name: string;
  icon: string;
  isAggregation: boolean;
  isEnable: boolean;
  onClick?: () => void;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInAnimation('350ms'), fadeOutAnimation('250ms')]
})
export class AppComponent implements OnInit, OnDestroy {
  readonly displayMainNav$: Observable<boolean>;
  readonly displayLeftNav$: Observable<boolean>;
  activeUser$: Observable<IUser | null> = of(null);
  readonly roles = Roles;

  currentUrl = '';
  clientConfig: IClientConfig;
  notificationPanelState: boolean = false;
  aggregationRoots: IAggregationStructure[] | null = null;
  path: IBreadcrumbPath[] | null = null;
  showRoots = false;
  navBarContent: INavBarContent[];
  applicationModules = ApplicationModules;
  tallyConfig: { formId: string | undefined; width: number } | undefined;

  private subs = new SubSink();

  constructor(
    { data$ }: RouteDataService,
    public readonly breadcrumbService: BreadcrumbService,
    public readonly appState: AppStateService,
    public readonly authService: AuthService,
    private readonly router: Router,
    private readonly appInit: AppInitService,
    private readonly aggregationStructureService: AggregationStructureService,
    @Inject(ENUMS) enums: IClientConfig,
    private readonly notificationService: NotificationService,
    private readonly ngbModal: NgbModal,
    private readonly configService: ConfigService,
    private readonly permissionService: PermissionService,
    private readonly powerBiService: PowerBiService,
    private readonly applicationPermissionService: ApplicationPermissionsService,
    private readonly userService: UserService,
    private logService: LogService
  ) {
    this.clientConfig = enums;
    this.displayMainNav$ = data$.pipe(map((data) => data.displayMainNav !== false));
    this.displayLeftNav$ = data$.pipe(map((data) => data.displayLeftNav !== false));

    /// Content of the left navbar, the variable isAggregation indicates if the route should be completed with an aggregation id
    this.navBarContent = [
      {
        value: 'performance',
        name: ApplicationModules.pastPerf,
        icon: 'leaderboard-fill',
        isAggregation: true,
        isEnable: !this.clientConfig.moduleAccess.promo
      },
      {
        value: 'scenarios',
        name: this.clientConfig.moduleAccess.promo ? ApplicationModules.optimization : ApplicationModules.scenarios,
        icon: 'insights',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.scenarios
      },
      {
        value: 'gap-analysis',
        name: ApplicationModules.gapAnalysis,
        icon: 'compare-arrows',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.gapAnalysis
      },
      {
        value: 'reports',
        name: ApplicationModules.reports,
        icon: 'input',
        isAggregation: true,
        isEnable: false
      },
      {
        value: 'digital-focus',
        name: 'Media Synergy',
        icon: 'center_focus_weak',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.digitalFocus
      },
      {
        value: 'promo/post-eval',
        name: 'Post eval',
        icon: 'assessment',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.promo?.postEval || false
      },
      {
        value: 'promo/efficiency-levers',
        name: 'Efficiency levers',
        icon: 'curves',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.promo?.efficiencyLevers || false,
        onClick: () => {
          this.logService.createLog({
            data: {},
            type: '/custom-efficiency-levers',
            user: ''
          });
        }
      },
      {
        value: 'promo/pre-eval',
        name: 'Pre eval',
        icon: 'donut_large',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.promo?.preEval || false
      },
      {
        value: 'promo/optimization',
        name: 'Optimization',
        icon: 'insights',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.promo?.optimization || false
      },
      {
        value: 'channel-performance',
        name: 'Channel perf',
        icon: 'near',
        isAggregation: true,
        isEnable: this.clientConfig.moduleAccess.channelPerformance
      },
      {
        value: 'debug',
        name: 'Debug',
        icon: 'settings',
        isAggregation: true,
        isEnable: isDebugToolsActive()
      }
    ];

    this.subs.sink = this.authService.user$
      .pipe(
        filter((user) => !!user),
        tap((user) => {
          this.notificationService.launchNotificationAutomaticPooler();
          if (user) this.activeUser$ = this.userService.getById(user.id);
        }),
        switchMap(() => this.aggregationStructureService.getRootAggregationStructures())
      )
      .subscribe((aggStruct) => {
        this.aggregationRoots = aggStruct;
      });
    this.breadcrumbService.path$.subscribe((path) => {
      this.path = path;

      const reportItem = this.navBarContent.find((item) => item.value === 'reports');
      if (reportItem) reportItem.isEnable = this.isReportTabEnabled();
    });

    if (this.clientConfig.GATrackingID && this.clientConfig.GATrackingID.length > 0) {
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.clientConfig.GATrackingID}`;
      document.head.appendChild(gTagManagerScript);

      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${this.clientConfig.GATrackingID}');
      `;
      document.head.appendChild(gaScript);

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', this.clientConfig.GATrackingID, {
            page_path: event.urlAfterRedirects
          });
        }
      });
    }
  }

  ngOnInit(): void {
    this.subs.sink = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.currentUrl = (event as NavigationEnd).url;
    });

    this.subs.sink = this.configService.getCurrent(this.clientConfig.aggregationWording).subscribe((currentConfig) => {
      this.appState.setConfigs(currentConfig);
      this.tallyConfig = currentConfig.tallyConfig;
    });
  }

  public disconnect(): void {
    this.authService.disconnect();
    this.closeTallyPoll();
    this.notificationService.stopNotificationAutomaticPooler();
    this.appInit.reset();
    this.router.navigate([ROUTER_LOGIN_PATH]);
  }

  ngOnDestroy(): void {
    this.notificationService.stopNotificationAutomaticPooler();
    this.subs.unsubscribe();
  }

  canAccessToConfiguration() {
    const allowedRoles = [Roles.admin, Roles.appManager, Roles.consultant];
    if (!this.authService.getCurrentUser()) return false;
    return (
      allowedRoles.includes(this.authService.getCurrentUser().role) ||
      this.applicationPermissionService.isPermitted(this.applicationModules.configuration)
    );
  }

  getCurrentAggregation(): void {
    const modalRef = this.ngbModal.open(AggregationStructureModalComponent, {
      animation: true,
      centered: true,
      windowClass: 'transparent-modal'
    });
    modalRef.componentInstance.aggregationStructures = this.aggregationRoots;
  }

  get urlConfiguration() {
    let notSerialized;
    if (this.permissionService.hasRoleAmongArray(this.authService.getCurrentUser(), [Roles.admin, Roles.appManager])) {
      const agg = this.appState.getAggregationStructures()?.[0];
      if (agg) {
        notSerialized = `/configuration/aggregation-structure/${agg.id}/details`;
      } else {
        notSerialized = '/configuration/aggregation-structure';
      }
    } else if (this.authService.getCurrentUser()?.role === Roles.consultant) {
      notSerialized = `/configuration/aggregation-structure`;
    } else {
      notSerialized = `/configuration/response-curves`;
    }
    return this.router.serializeUrl(this.router.createUrlTree([notSerialized]));
  }

  getNotificationsCount() {
    return this.notificationService.unreadNotificationsCount;
  }

  changeNotificationPanelState(newState: boolean) {
    this.notificationPanelState = newState;
  }

  getBreadcrumbsPath(aggStructId: string) {
    if (this.clientConfig.moduleAccess.promo) {
      return `promo/post-eval/${aggStructId}`;
    }
    return `performance/${aggStructId}`;
  }

  onBreadcrumbsClick(aggStructId: string) {
    if (this.clientConfig.moduleAccess.promo) {
      return this.router.navigate([`promo/post-eval/${aggStructId}`]);
    }
    if (this.showRoots) this.showRoots = false;
    this.router.navigate([`performance/${aggStructId}`]);
    return;
  }

  getUrlRouterLink(navElement: any, aggStruct: IAggregationStructure) {
    let notSerialized;
    if (this.isLinkDisabled(navElement.value, aggStruct)) return null;
    let condition = this.getNavElementCondition(navElement.value, aggStruct);
    if (condition) {
      let result = ['/' + navElement.value];
      if (navElement.isAggregation) result.push('/' + aggStruct.id);
      notSerialized = result.join('');
    }
    if (notSerialized) return this.router.serializeUrl(this.router.createUrlTree([notSerialized]));
    return null;
  }

  get urlHelp() {
    return this.router.serializeUrl(this.router.createUrlTree(['/help']));
  }

  isLinkDisabled(route: string, aggStruct: IAggregationStructure) {
    let condition = this.getNavElementCondition(route, aggStruct);
    if (condition) {
      return '';
    }
    return 'disabled';
  }

  onSelectBreadcrumb(breadcrumb?: IBreadcrumbPath) {
    if (this.path) {
      this.path = this.path.map((path) => ({
        ...path,
        dropdown: path.id !== breadcrumb?.id ? false : !breadcrumb.dropdown
      }));
    }
    if (breadcrumb) {
      this.showRoots = false;
    } else {
      this.showRoots = !this.showRoots;
    }
  }

  getHomePath() {
    return '/';
  }

  isTallyPollDisplayed(user: IUser): boolean {
    return (this.currentUrl && !this.currentUrl.includes('/login') && this.tallyConfig?.formId && !user.answeredPoll) || false;
  }

  private closeTallyPoll(): void {
    const Tally = (window as any).Tally;
    Tally.closePopup(this.tallyConfig?.formId);
  }

  private isReportTabEnabled() {
    if (!this.applicationPermissionService.isPermitted(ApplicationModules.reports) || !this.path || this.path.length === 0) return false;

    const reportConfig = this.path[this.path.length - 1];
    return !!reportConfig.reports?.displayReports;
  }

  private getNavElementCondition(element: string, aggStruct: IAggregationStructure) {
    switch (element) {
      case 'home':
        return aggStruct.modelsIds.length !== 0;
      case 'compare':
        return aggStruct.modelsIds.length !== 0;
      case 'performance':
        return aggStruct.modelsIds.length !== 0;
      case 'simulation':
        return aggStruct.scenarioNb !== 0;
      case 'scenarios':
        return (
          aggStruct.modelsIds.length !== 0 &&
          aggStruct?.models?.find((model) => model.status === MMOModelStatus.complete || model.status === MMOModelStatus.archived)
        );
      case 'digital-focus':
        return aggStruct.children?.length === 0;
      case 'reports':
        if (!this.path || this.path.length === 0) return false;
        const reports = this.powerBiService.getReportsToDisplay(this.path);
        return !!reports;
      default:
        return true;
    }
  }
}
